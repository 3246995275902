import cn from 'classnames'

export const focusStyles = {
  base: 'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-app-orange-base/50 focus-visible:ring-offset-1 autofill:bg-app-orange-base',
  get primary() {
    return this.base + ' focus-visible:ring-app-orange-base'
  },
  get red() {
    return this.base + ' focus-visible:ring-red-200'
  },
}

export const inputStyles = {
  textbox: cn('py-2 px-3  block w-full border rounded-md', focusStyles.base),
  select: cn(
    'py-3 px-3 pr-9 bg-white border border-gray-200 block w-full border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400'
  ),
  checkbox: cn(
    'shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
  ),
  checkboxLabel: cn('text-sm text-gray-500 ml-3 dark:text-gray-400'),
}
